$.each($('.center-slider'),function (i,el){
  $(el).slick({
    centerMode: true,
    slidesToShow: 3,
    arrows: true,
    variableWidth: true,
    prevArrow: $(el).parents('.slider-block').find('.prev-arrow'),
    nextArrow: $(el).parents('.slider-block').find('.next-arrow'),
    responsive: [
      {
        breakpoint: 993,
        settings: {
          variableWidth: false,
          arrows: true,
          dots: true,
          centerMode: true,
          slidesToShow: 1
        }
      },
    ]
  });
})
$('.tabs-switch-item').click(function(){
  $('.tabs-switch-item').removeClass('active')
  $(this).addClass('active')
  $('.tabs-content').addClass('d-none');
  $(`[data-content="${$(this).data('tab')}"]`).removeClass('d-none');
})
$('.button-menu').click(function(){
  $('.menu').toggleClass('opened');
})
$('.open-form').click(function(){
  $('.block-item-form').show();
})
$('.hide-form').click(function(){
  $('.block-item-form').hide();
  $('.tnx-screen').hide();
})
$(".menu li,.footer-item-menu li").click(function() {
  $(this).parents('.menu').removeClass('opened')
  $([document.documentElement, document.body]).animate({
    scrollTop: $(`#${$(this).data('id')}`).offset().top
  }, 1000);
});
$(".scroll-top").click(function() {
  if(window.innerWidth<993){
    $('.open-form').click()
    return false
  }
  $([document.documentElement, document.body]).animate({
    scrollTop: $(`#header`).offset().top
  }, 2000);
});
function getFormData($form) {
  var unindexed_array = $form.serializeArray();
  var indexed_array = {};
  $.map(unindexed_array, function(n, i) {
    if (n["value"] !== "") {
      indexed_array[n["name"]] = n["value"];
    }
  });
  return indexed_array;
}
$('.input-container-btn .btn').click(function(e){
  e.preventDefault()
  if ($(this).parents('form').validationEngine("validate")) {
    let  dataCall = getFormData($(this).parents('form'))
    if(!dataCall.email){
      dataCall.email = ''
    }
    let self =  $(this)
    $.ajax({
      url: "https://ceres.planetavto.ua/send/vip",
      type: "POST",
      data: dataCall,
      dataType: "json",
      success: function(data) {
        self.parents('form')[0].reset();
        $.notify("Заявку надіслано!", "success");
      },error: function(e) {
        $.notify("Виникла помилка!", "error");
      },
    });
  }
  return false
})
if(document.location.search == '?utm_source=qr_code_sticker'){
  $([document.documentElement, document.body]).animate({
    scrollTop: $(`#cars`).offset().top
  }, 100);
}
$('.form form').validationEngine("attach", { scroll: false });
$.notify.defaults({globalPosition: 'bottom right'})



